import { Fragment, useEffect } from "react"
import PageEntete from "../../Component/PageEntete"
import '../../Styles/Page.css'
import cep from '../../images/treillis/cep.jpg'
import TreillisInter from "../../Component/TreillisInter"
import { useTranslation } from "react-i18next"



function CEP() {

  useEffect(() => {
    window.scrollTo( {top: "0", behavior: "smooth"} )
  }, [])
  
  const[t, i18n]= useTranslation("global")

  const avFr= [
    {av: "L'acier est connu pour sa résistance élevée, ce qui en fait un matériau idéal pour les cadres, épingles et entretoises. Ces éléments en acier peuvent supporter des charges importantes, contribuant ainsi à la stabilité et à la solidité des structures..", title: "Résistance structurelle:" },
    {av: "L'acier est un matériau durable et résistant à la corrosion, ce qui signifie que les cadres, épingles et entretoises en acier ont une longue durée de vie. Ils nécessitent peu d'entretien et peuvent résister aux conditions environnementales difficiles.", title: "Durabilité:" },
    {av: "Les cadres en acier peuvent être fabriqués dans une variété de formes et de tailles, offrant ainsi une grande flexibilité de conception. Cela permet aux architectes et aux ingénieurs de créer des structures complexes et innovantes.", title: "Flexibilité de conception:" },
    {av: "Les composants en acier peuvent offrir une résistance structurelle élevée tout en étant relativement légers par rapport à d'autres matériaux. Cela peut contribuer à réduire le poids total de la structure, ce qui peut être important dans certaines applications.", title: "Réduction du poids:" },
    {av: "Les cadres, épingles et entretoises en acier peuvent être associés à d'autres matériaux de construction, offrant ainsi une grande polyvalence dans la conception et la construction.", title: "Compatibilité avec d'autres matériaux:" },
  ]
  const appFr= [
    {app: "Les treillis soudés en acier sont souvent utilisés pour former des cadres et des étriers dans le béton armé. Ils renforcent les éléments structurels tels que les poutres, les colonnes et les dalles.", title: "Construction de Béton Armé:"},
    {app: "Dans la construction de fondations en béton, les treillis soudés en acier peuvent être utilisés pour renforcer les structures et assurer la stabilité.", title: "Fondations:"},
    {app: "Les treillis soudés en acier sont intégrés dans les éléments préfabriqués en béton, tels que les panneaux muraux et les éléments de plancher, pour renforcer ces structures.", title: "Structures en Béton Préfabriqué:"},
    {app: "Les cadres en treillis soudés en acier peuvent être utilisés dans divers projets de génie civil, tels que les murs de soutènement, les réservoirs d'eau et les structures de drainage.", title: "Travaux de Génie Civil:"},
    {app: "Dans l'agriculture, les treillis soudés en acier peuvent être utilisés pour former des cadres et des étriers dans les structures agricoles telles que les serres.", title: "Applications Agricoles:"},
    {app: "Ces treillis peuvent être utilisés temporairement dans des projets de construction ou d'événements spéciaux pour des besoins de support temporaire."}
  ]

  const avGb= [
    {av: "Steel is known for its high strength, making it an ideal material for frames, pins and struts. These steel elements can support significant loads, contributing to the stability and strength of structures ..", title: "Structural resistance:" },
     {av: "Steel is a durable, corrosion-resistant material, which means steel frames, pins and struts have a long lifespan. They require little maintenance and can withstand harsh environmental conditions. ", title: "Sustainability:" },
     {av: "Steel frames can be manufactured in a variety of shapes and sizes, providing great design flexibility. This allows architects and engineers to create complex and innovative structures.", title: "Flexibility of design:" },
     {av: "Steel components can provide high structural strength while being relatively lightweight compared to other materials. This can help reduce the overall weight of the structure, which can be important in some applications.", title: "Weight reduction:" },
     {av: "Steel frames, pins and braces can be combined with other building materials, providing great versatility in design and construction.", title: "Compatibility with other materials:" },
  ]
  const appGb= [
    {app: "Welded steel mesh is often used to form frames and stirrups in reinforced concrete. It reinforces structural elements such as beams, columns and slabs.", title: "Reinforced Concrete Construction: "},
     {app: "In the construction of concrete foundations, welded steel mesh can be used to reinforce structures and provide stability.", title: "Foundations:"},
     {app: "Welded steel mesh is integrated into precast concrete elements, such as wall panels and floor elements, to reinforce these structures.", title: "Precast Concrete Structures:"},
     {app: "Welded steel mesh frames can be used in various civil engineering projects, such as retaining walls, water tanks and drainage structures.", title: "Civil Engineering Works:" },
     {app: "In agriculture, welded steel mesh can be used to form frames and stirrups in agricultural structures such as greenhouses.", title: "Agricultural Applications:"},
     {app: "These trellises may be used temporarily in construction or special event projects for temporary support needs."}
  ]

  const avDz= [
    {av: "يشتهر الفولاذ بقوته العالية، مما يجعله مادة مثالية للإطارات والمسامير والدعامات. يمكن لهذه العناصر الفولاذية تحمل أحمال كبيرة، مما يساهم في استقرار وقوة الهياكل ..", title: "المقاومة الهيكلية: " },
     {av: "الصلب مادة متينة ومقاومة للتآكل، مما يعني أن الإطارات والمسامير والدعامات الفولاذية تتمتع بعمر افتراضي طويل. وهي تتطلب القليل من الصيانة ويمكنها تحمل الظروف البيئية القاسية. ", title: "الاستدامة:" },
     {av: "يمكن تصنيع الإطارات الفولاذية بأشكال وأحجام متنوعة، مما يوفر مرونة كبيرة في التصميم. وهذا يسمح للمهندسين المعماريين والمهندسين بإنشاء هياكل معقدة ومبتكرة.", title: "مرونة التصميم:" },
     {av: "يمكن أن توفر المكونات الفولاذية قوة هيكلية عالية مع كونها خفيفة الوزن نسبيًا مقارنة بالمواد الأخرى. يمكن أن يساعد هذا في تقليل الوزن الإجمالي للهيكل، وهو ما قد يكون مهمًا في بعض التطبيقات.", title: "تقليل الوزن:" },
     {av: "يمكن دمج الإطارات والدبابيس والأقواس الفولاذية مع مواد بناء أخرى، مما يوفر تنوعًا كبيرًا في التصميم والبناء.", title: "التوافق مع المواد الأخرى:" },
  ]
  const appDz= [
    {app: "غالبًا ما تُستخدم شبكة الفولاذ الملحومة لتشكيل الإطارات والركاب في الخرسانة المسلحة. وهي تعمل على تقوية العناصر الهيكلية مثل الكمرات والأعمدة والألواح.", title: "الإنشاءات الخرسانية المسلحة: "},
     {app: "في بناء الأساسات الخرسانية، يمكن استخدام شبكة فولاذية ملحومة لتعزيز الهياكل وتوفير الاستقرار.", title: "الأساسات:"},
     {app: "تم دمج الشبكة الفولاذية الملحومة في العناصر الخرسانية مسبقة الصب، مثل ألواح الجدران وعناصر الأرضية، لتعزيز هذه الهياكل.", title: "الهياكل الخرسانية مسبقة الصب:"},
     {app: "يمكن استخدام إطارات الشبكات الفولاذية الملحومة في العديد من مشاريع الهندسة المدنية، مثل الجدران الاستنادية وخزانات المياه وهياكل الصرف الصحي.", title: "أعمال الهندسة المدنية:" },
     {app: "في الزراعة، يمكن استخدام الشبكات الفولاذية الملحومة لتشكيل الإطارات والركاب في الهياكل الزراعية مثل الدفيئات الزراعية.", title: "التطبيقات الزراعية:"},
     {app: "يمكن استخدام هذه التعريشات مؤقتًا في مشاريع البناء أو المناسبات الخاصة لتلبية احتياجات الدعم المؤقتة."}
  ]

  return (
    <>
      <TreillisInter 
        id= "cep"
        GoTo= {"poutrelle"} GoDown= {"panneaux"}
        pdf="/pdf/Treillis/cep.pdf" 
        pdfGlobal="/pdf/Treillis/all.pdf" 
        appFr= {appFr} avFr= {avFr}
        appGb= {appGb} avGb= {avGb}
        appDz= {appDz} avDz= {avDz}
        image= {"cep.jpg"}
        figureUno= {"cepUno.jpg"}
        figureDos= {"cepDos.jpg"}
        cep= {true}
      />
    </>
  )
}

export default CEP