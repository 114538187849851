import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import HomeIcon from '@mui/icons-material/Home'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { Link } from 'react-router-dom'
import '../Styles/Page.css'
import '../index.css'
import logo from '../images/logo.png'
import {useTranslation} from "react-i18next"

function PageEntete( {title, type, GoDown, GoTo, pdfGlobal, show} ) {
    const [t, i18n]= useTranslation("global")

  return (
    <div className= 'Page_entete' style= {{height: show ? "10vh": 0, opacity: show ? 1: 0, transition: "all .25s ease-in-out"}}>
        <Link to= "/" state={{ section: "home", firstLoad: false }} >
            <Tooltip title= {t("interface.tooltip.home")}>
                <HomeIcon id= "Page_icon" />
            </Tooltip>
        </Link>
        <div id= "Page_title" style= {{textAlign: "center"}}>
            <Link to= {`/${GoDown}`} >
                <Tooltip title= {`${t("interface.tooltip.goBack")} ${type}`} >
                    <ArrowBackIcon id= "Page_icon" />
                </Tooltip>
            </Link>
            <h1>{title}</h1>
            <Link to= {`/${GoTo}`}>
                <Tooltip title= {`${t("interface.tooltip.goTo")} ${type}`} >
                    <ArrowForwardIcon id= "Page_icon" />
                </Tooltip>
            </Link>
            <img src= {logo} alt="" />
        </div>
        <Link
                to= {pdfGlobal}
                download= {`Fiche_Technique_Globale`}
                target="_blank"
                rel= "noopener noreferrer"
            >
                <Tooltip title= {`${t("interface.tooltip.pdfGlobal")}`} >
                    <PictureAsPdfIcon id= "Page_icon" />
                </Tooltip>
        </Link>

    </div>
  )
}

export default PageEntete