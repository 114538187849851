import { Fragment } from "react"
import PanneauxInter from "../../Component/PanneauxInter"
import { useTranslation } from "react-i18next"

function Panneau2D() {

  const appFr= [
    {app: "Les barres principales sont alignées dans une direction, formant ainsi la base structurelle du panneau. Elles peuvent être disposées horizontalement ou verticalement en fonction de l'orientation du treillis.", title: "Barres Principales (Longitudinales):"},
    {app: "Les barres transversales sont soudées perpendiculairement aux barres principales pour former des intersections, créant ainsi une grille bidimensionnelle.", title: "Barres Transversales (Transversales):"},
    {app: "Les intersections des barres principales et transversales forment des mailles rectangulaires, créant une structure en grille bidimensionnelle. La taille de ces mailles peut varier en fonction des besoins du projet.", title: "Mailles Rectangulaires:"},
    {app: "Les barres principales et transversales sont reliées par soudure aux points d'intersection pour assurer la stabilité structurelle du panneau 2D.", title: "Soudure:"},
    {app: "Les dimensions du panneau 2D comprennent la longueur et la largeur du treillis, ainsi que l'épaisseur des barres qui détermine la résistance et la rigidité du treillis.", title: "Dimensions:"},
    {app: "L'épaisseur des barres d'acier utilisées dans le treillis 2D influence directement sa résistance. Des barres plus épaisses sont généralement utilisées pour des applications nécessitant une plus grande robustesse.", title: "Épaisseur des Barres:"},
    {app: "Certains panneaux de treillis 2D peuvent avoir des revêtements spéciaux pour résister à la corrosion ou des finitions esthétiques pour répondre à des exigences spécifiques de conception.", title: "Revêtements et Finitions (le cas échéant):"},
  ]
  const avFr= [
    {av: "Les panneaux 2D sont généralement plats et légers, facilitant leur transport et leur installation sur le site de construction.", title: "Facilité d'installation:"},
    {av: "Les panneaux 2D peuvent être rapidement installés, ce qui accélère le processus de construction par rapport à d'autres méthodes de construction plus complexes.", title: "Rapidité de construction:"},
    {av: "En raison de leur fabrication standardisée et de leur facilité d'utilisation, les panneaux 2D peuvent offrir des coûts compétitifs par rapport à d'autres solutions de construction plus personnalisées.", title: "Coûts compétitifs:"},
    {av: "Certains types de panneaux 2D peuvent être équipés de matériaux isolants pour améliorer les propriétés thermiques des bâtiments.", title: "Isolation thermique:"},
    {av: "Comparés à certains matériaux de construction plus lourds, les panneaux 2D sont généralement légers, ce qui peut être avantageux pour certaines applications et réduire les contraintes structurelles.", title: "Poids léger:"}
  ]

  const appGb= [
    {app: "The main bars are aligned in one direction, forming the structural basis of the panel. They can be arranged horizontally or vertically depending on the orientation of the mesh.", title: "Main Bars (Longitudinal):"} ,
     {app: "Cross bars are welded perpendicular to the main bars to form intersections, creating a two-dimensional grid.", title: "Cross Bars (Transversales):"},
     {app: "The intersections of the main and cross bars form rectangular meshes, creating a two-dimensional grid structure. The size of these meshes can vary depending on the needs of the project.", title: "Rectangular Meshes:"},
     {app: "The main and cross bars are connected by welding at the intersection points to ensure the structural stability of the 2D panel.", title: "Welding:"},
     {app: "The dimensions of the 2D panel include the length and width of the mesh, as well as the thickness of the bars which determines the strength and stiffness of the mesh.", title: "Dimensions:"},
     {app: "The thickness of the steel bars used in the 2D truss directly influences its strength. Thicker bars are generally used for applications requiring greater strength.", title: "Bar Thickness:"},
     {app: "Some 2D mesh panels may have special coatings to resist corrosion or aesthetic finishes to meet specific design requirements.", title: "Coatings and Finishes (if applicable):"},
  ]
  const avGb= [
    {av: "2D panels are generally flat and lightweight, making them easy to transport and install at the construction site.", title: "Ease of installation:"},
     {av: "2D panels can be quickly installed, speeding up the construction process compared to other, more complex construction methods.", title: "Speed of construction:"},
     {av: "Due to their standardized manufacturing and ease of use, 2D panels can offer competitive costs compared to other, more customized building solutions.", title: "Competitive costs:"},
     {av: "Certain types of 2D panels can be equipped with insulating materials to improve the thermal properties of buildings.", title: "Thermal insulation:"},
     {av: "Compared to some heavier building materials, 2D panels are generally lightweight, which can be advantageous for certain applications and reduce structural stresses.", title: "Light weight:"}
  ]

  const appDz= [
    {app: "تتم محاذاة الأشرطة الرئيسية في اتجاه واحد، لتشكل الأساس الهيكلي للوحة. ويمكن ترتيبها أفقيًا أو رأسيًا اعتمادًا على اتجاه الشبكة.", title: "الأشرطة الرئيسية (الطولية):"} ,
    {app: "يتم لحام القضبان المتقاطعة بشكل عمودي على القضبان الرئيسية لتكوين تقاطعات، مما يؤدي إلى إنشاء شبكة ثنائية الأبعاد.", title: "القضبان المتقاطعة (القضبان المستعرضة):"},
    {app: "تشكل تقاطعات القضبان الرئيسية والمتقاطعة شبكات مستطيلة، مما يؤدي إلى إنشاء بنية شبكية ثنائية الأبعاد. يمكن أن يختلف حجم هذه الشبكات وفقًا لاحتياجات المشروع.", title: "شبكات مستطيلة:"},
    {app: "يتم توصيل القضبان الرئيسية والعرضية عن طريق اللحام عند نقاط التقاطع لضمان الاستقرار الهيكلي للوحة ثنائية الأبعاد.", title: "لحام:"},
    {app: "تتضمن أبعاد اللوحة ثنائية الأبعاد طول الشبكة وعرضها، بالإضافة إلى سمك القضبان الذي يحدد قوة الشبكة وصلابتها.", title: "الأبعاد:"},
    {app: "يؤثر سمك القضبان الفولاذية المستخدمة في الجمالون ثنائي الأبعاد بشكل مباشر على قوتها. تُستخدم القضبان الأكثر سمكًا بشكل عام في التطبيقات التي تتطلب قوة أكبر.", title: "سمك القضيب:"},
    {app: "قد تحتوي بعض اللوحات الشبكية ثنائية الأبعاد على طبقات طلاء خاصة لمقاومة التآكل أو التشطيبات الجمالية لتلبية متطلبات التصميم المحددة.", title: "الطلاءات والتشطيبات (إن أمكن):"},
  ]
  const avDz= [
    {av: "الألواح ثنائية الأبعاد بشكل عام مسطحة وخفيفة الوزن، مما يجعلها سهلة النقل والتركيب في موقع البناء.", title: "سهولة التثبيت:"},
     {av: "يمكن تركيب الألواح ثنائية الأبعاد بسرعة، مما يؤدي إلى تسريع عملية البناء مقارنة بطرق البناء الأخرى الأكثر تعقيدًا.", title: "سرعة البناء:"},
     {av: "نظرًا لتصنيعها الموحد وسهولة استخدامها، يمكن للألواح ثنائية الأبعاد تقديم تكاليف تنافسية مقارنة بحلول البناء الأخرى الأكثر تخصيصًا.", title: "تكاليف تنافسية:"},
     {av: "يمكن تجهيز أنواع معينة من الألواح ثنائية الأبعاد بمواد عازلة لتحسين الخواص الحرارية للمباني.", title: "العزل الحراري:"},
     {av: "بالمقارنة مع بعض مواد البناء الأثقل، تعد الألواح ثنائية الأبعاد خفيفة الوزن بشكل عام، مما قد يكون مفيدًا لتطبيقات معينة ويقلل الضغوط الهيكلية.", title: "خفيفة الوزن:"}
  ]
  return (
    <div>
      <PanneauxInter 
        id= "2d"
        GoTo= {"panneau_3d"} GoDown= {"panneau_simple"} image= {'2d.jpg'}
        pdf= "/pdf/panneaux/2d.pdf" 
        pdfGlobal= "/pdf/panneaux/all.pdf" 
        appFr= {appFr} avFr= {avFr}
        appGb= {appGb} avGb= {avGb}
        appDz= {appDz} avDz= {avDz}
        figureUno= {"2dUno.jpg"}
        figureDos= {"2dDos.jpg"}
      />
    </div>
  )
}

export default Panneau2D