import { Fragment, useEffect } from "react"
import PageEntete from "../../Component/PageEntete"
import '../../Styles/Panneau.css'
import '../../Styles/Page.css'
import PiquetInter from "../../Component/PiquetInter"

function PiquetLourd() {

  const appFr= [
    {app: "Les piquets lourds en acier de 60 cm peuvent être utilisés pour la construction de clôtures de jardin, fournissant une solution stable pour délimiter des zones spécifiques.", title: "Fixation de Clôtures de Jardin:"},
    {app: "En raison de leur robustesse, ces piquets peuvent être utilisés pour soutenir des panneaux de signalisation temporaires, notamment sur des chantiers de construction, des événements ou des situations nécessitant une signalisation spécifique.", title: "Support pour Panneaux de Signalisation:"},
    {app: "Dans le domaine agricole, ces piquets peuvent être utilisés pour soutenir des filets de protection autour des cultures, protégeant ainsi les plantes des animaux ou des conditions météorologiques défavorables.", title: "Soutien de Filets Agricoles:"},
    {app: "Ils sont adaptés à des applications d'aménagement paysager, tels que la fixation de bordures, la création de chemins délimités, ou la séparation de zones spécifiques dans le jardin.", title: "Aménagement Paysager:"},
    {app: "Ces piquets peuvent également être utilisés dans des projets de bricolage, par exemple pour la construction de petites structures temporaires ou la fixation d'objets dans le sol.", title: "Projets de Bricolage:"},
  ]
  const avFr= [
    {av: "En raison de leur poids accru et de leur construction en acier, ces piquets offrent une stabilité et une résistance supérieures, les rendant adaptés à des applications où la robustesse est essentielle.", title: "Stabilité et Résistance:"},
    {av: "Leur taille de 60 cm les rend polyvalents pour différentes applications, offrant une solution intermédiaire entre des piquets plus courts et plus longs.", title: "Polyvalence:"},
    {av: "Les piquets lourds en acier sont adaptés à une utilisation intensive, ce qui les rend appropriés pour des applications permanentes ou temporaires dans des conditions exigeantes.", title: "Utilisation Intensive:"},
    {av: "En raison de leur construction en acier, ces piquets sont généralement durables et résistent bien aux intempéries, ce qui réduit les besoins en entretien.", title: "Durabilité:"},
    {av: "Certains modèles peuvent être conçus pour faciliter leur enfoncement dans le sol, ce qui simplifie l'installation, même sans l'utilisation d'outils spécialisés.", title: "Installation Facile"},
  ]
  const appGb= [
    {app: "The heavy duty 60cm steel stakes can be used for constructing garden fences, providing a stable solution for demarcating specific areas.", title: "Garden Fence Fixing:"},
    {app: "Due to their robustness, these stakes can be used to support temporary signage, especially on construction sites, events or situations requiring specific signage.", title: "Support for Signage :"},
    {app: "In agriculture, these stakes can be used to support protective netting around crops, protecting plants from animals or adverse weather conditions.", title: "Agricultural Net Support:"},
    {app: "They are suitable for landscaping applications, such as fixing borders, creating demarcated paths, or separating specific areas in the garden.", title: "Landscaping:"},
    {app: "These stakes can also be used in DIY projects, for example for building small temporary structures or securing objects in the ground.", title: "DIY Projects:"},
  ]
  const avGb= [
    {av: "Due to their increased weight and steel construction, these stakes offer superior stability and strength, making them suitable for applications where robustness is essential.", title: "Stability and Strength:"} ,
    {av: "Their 60cm size makes them versatile for different applications, providing an intermediate solution between shorter and longer stakes.", title: "Versatility:"},
    {av: "Heavy duty steel stakes are suitable for heavy duty use, making them suitable for permanent or temporary applications in demanding conditions.", title: "Heavy Duty:"},
    {av: "Due to their steel construction, these stakes are generally durable and hold up well to bad weather, reducing maintenance requirements.", title: "Durability:"},
    {av: "Some models may be designed to be easier to sink into the ground, making installation simpler, even without the use of specialized tools.", title: "Easy Installation"}
  ]
  const appDz= [
    {app: "يمكن استخدام الأوتاد الفولاذية شديدة التحمل مقاس 60 سم لبناء أسوار الحديقة، مما يوفر حلاً ثابتًا لترسيم مناطق محددة.", title: "تثبيت سياج الحديقة:"},
    {app: "نظرًا لقوتها، يمكن استخدام هذه الأوتاد لدعم اللافتات المؤقتة، خاصة في مواقع البناء أو الأحداث أو المواقف التي تتطلب لافتات محددة.", title: "دعم اللافتات :"},
    {app: "في الزراعة، يمكن استخدام هذه الأوتاد لدعم الشباك الواقية حول المحاصيل، وحماية النباتات من الحيوانات أو الظروف الجوية المعاكسة.", title: "دعم الشبكة الزراعية:"},
    {app: "إنها مناسبة لتطبيقات تنسيق الحدائق، مثل تثبيت الحدود أو إنشاء مسارات محددة أو فصل مناطق معينة في الحديقة.", title: "تنسيق الحدائق:"},
    {app: "يمكن أيضًا استخدام هذه الأوتاد في مشاريع DIY، على سبيل المثال لبناء هياكل مؤقتة صغيرة أو تأمين الأشياء في الأرض.", title: "مشاريع DIY:"}
  ]
  const avDz= [
    {av: "نظرًا لوزنها المتزايد وبنيتها الفولاذية، توفر هذه الأوتاد ثباتًا وقوة فائقين، مما يجعلها مناسبة للتطبيقات التي تكون فيها المتانة ضرورية.", title: "الاستقرار والقوة:"} ,
    {av: "حجمها الذي يبلغ 60 سم يجعلها متعددة الاستخدامات لتطبيقات مختلفة، مما يوفر حلاً متوسطًا بين الرهانات الأقصر والأطول.", title: "التنوع:"},
    {av: "الأوتاد الفولاذية شديدة التحمل مناسبة للاستخدام الثقيل، مما يجعلها مناسبة للتطبيقات الدائمة أو المؤقتة في الظروف الصعبة.", title: "الخدمة الشاقة:"},
    {av: "نظرًا لبنيتها الفولاذية، فإن هذه الأوتاد متينة بشكل عام وتتحمل جيدًا الطقس السيئ، مما يقلل من متطلبات الصيانة.", title: "المتانة:"},
    {av: "قد يتم تصميم بعض الطرز بحيث يكون من الأسهل دفنها في الأرض، مما يجعل التثبيت أسهل، حتى بدون استخدام أدوات متخصصة.", title: "التثبيت السهل"},
  ]

  
  return (
    <div>
      <PiquetInter 
        id= "lourd"
        GoTo= {"piquet_sonatres"} GoDown= {"piquet_rectangulaire"}
        pdf= '/pdf/Piquets/60_lourd.pdf'
        pdfGlobal= '/pdf/Piquets/all.pdf'
        appFr= {appFr} avFr= {avFr}
        appGb= {appGb} avGb= {avGb}
        appDz= {appDz} avDz= {avDz}
        mainImage= {"lourd.jpg"} platine= {"lourd_platine.jpg"} fouille= {"lourd_fouille.jpg"}
        bouchon= {"lourd_bouchon.jpg"} dimension= {"lourd_dimension.jpg"}
      />


      {/*
      <PageEntete title= {"PIQUET 60 CM LOURD"} GoTo= {"piquet_sonatres"} GoDown= {"piquet_rectangulaire"} />
      <div className="Panneau_main">
        <div className="Panneau_pres" style= {{alignItems: "flex-start"}}>
          <div className="pres"  style= {{flexDirection: "row", justifyContent: "space-evenly"}}>
            <h2>PRESENTATION</h2>
            <div className="">
              <h4>60 CM Lourd</h4>
              <img src= {lourd} alt="" />
            </div>
            <div className="">
              <h4>Fouille</h4>
              <img src= {fouille} alt="" />
            </div>
          </div>
          <div className="dimension">
            <h2>DIMENSION</h2>
            <img src= {dimension} alt="" />
            <img src= {bouchon} alt="" />
          </div>
        </div>

        <div style= {{width: "100%", height: "auto", display: "flex", flexDirection: "column", gap: "0", alignItems: "center"}}>
          <h2>PIQUET 60 CM LOURD</h2>
          <table className="tb">
            <thead>
              <tr>
                <th className="tbh">⊘fil</th>
                <th className="tbh">Epaisseur (mm)</th>
                <th className="tbh">Hauteur (mm)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tbd">60</td>
                <td className="tbd">2,0</td>
                <td className="tbd">2,70</td>
              </tr>
              <tr>
                <td className="tbd">60</td>
                <td className="tbd">2.0</td>
                <td className="tbd">2,9</td>
              </tr>
              <tr>
                <td className="tbd">60</td>
                <td className="tbd">2.0</td>
                <td className="tbd">3,20</td>
              </tr>
            </tbody>
          </table>
        </div>
          <div className="Panneau_second">
            <div className="objet">
                <h3>OBJET</h3>
                <p>Piquet en tôle acier profilé à froid<br /> &nbsp;Norme: EN 10346.</p>
            </div>
            <div className="objet">
                <h3>REVÊTEMENT</h3>
                <p>TRAITEMENT DE SURFACE, Dégraissage, rinçage et phosphatation.</p>
            </div>
            <div className="objet">
                <h3>MATIERES PREMIERES</h3>
                <p>tôle en acier à bas carbone<br /> &nbsp;pour le formage à froid.</p>
            </div>
            <div className="objet">
                <h3>PLASTIFICATION</h3>
                <p>Poudrage polyester (min 80 microns) <br />&nbsp; Polymérisation par cuisson au four.</p>
            </div>
            <div className="objet">
                <h3>ACIER DE NUANCE S235JR.</h3>
                <p>Norme: EN 10025-2.</p>
            </div>
            <div className="objet">
                <h3>NORME</h3>
                <p>NF EN 10245-4.</p>
            </div>
          </div>
      </div>
  */}
    </div>
  )
}

export default PiquetLourd